<template>
  <div id="app">
    <!-- 移动端顶部导航栏 -->
    <div class="top-nav">
      <h1>洗鞋大师</h1>
      <div class="menu" :class="{ close: showMenu }" @click="menuToggle"></div>
    </div>
    <ul class="top-nav-box" :class="{ active: !showMenu }">
      <li class="top-nav-item" v-for="(item, index) in tabList" :key="index">
        <a
          :href="item.id"
          :data-id="item.id"
          :data-index="index"
          @click="tabClick"
          >{{ item.name }}</a
        >
      </li>
    </ul>
    <!-- <div class="seize"></div> -->

    <div class="wrapper" @touchstart="handleTouchStart">
      <!-- 开屏页 -->
      <section class="screen" id="screen">
        <!-- 大背景 -->
        <div class="screen-bg">
          <img class="fit-img" src="./assets/images/screen_bg.png" alt="" />
        </div>

        <!-- 遮罩层 -->
        <div class="mask"></div>

        <div class="top-wrapper">
          <!-- logo -->
          <h1 class="logo-box">
            <img class="fit-img" src="./assets/images/logo.png" alt="" />
            洗鞋大师
          </h1>
          <!-- 右侧tab栏 -->
          <ul class="tab-box">
            <li
              class="item"
              :class="{ active: tabActiveText === item.id }"
              v-for="(item, index) in tabList"
              :key="index"
            >
              <a :href="item.id" :data-id="item.id" @click="tabClick">{{
                item.name
              }}</a>
            </li>
          </ul>
        </div>

        <!-- 主体内容 -->
        <div class="container-box">
          <div class="mobile-box">
            <img class="fit-img" src="./assets/images/mobile.png" alt="" />
          </div>
          <div class="right">
            <div class="title-box">
              <img
                class="fit-img"
                src="./assets/images/shoes_text.png"
                alt=""
              />
            </div>
            <div class="sub-text">互联网洗鞋&nbsp;开启洗鞋新模式</div>
            <div class="process-box">
              <img class="fit-img" src="./assets/images/process.png" alt="" />
            </div>
            <div class="scan-box">
              <img class="fit-img" src="./assets/images/scan_code.png" alt="" />
            </div>
          </div>
        </div>

        <!-- 移动端标题内容 -->
        <div class="mobile-title-box">
          <div class="mobile-title">互联网洗鞋&nbsp;开启行业新模式</div>
          <div class="mobile-subtitle">
            <div class="item">洗鞋</div>
            <div class="item">护理</div>
            <div class="item">消毒</div>
            <div class="item">修复</div>
          </div>
        </div>
      </section>

      <!-- 新模式 -->
      <section class="new-mode" id="new_mode">
        <div class="title-desc">
          <div class="title-text">开启洗鞋行业新模式</div>
          <div class="subtitle">
            <div class="line"></div>
            全天候24小时随存随取，一鞋一码实时了解洗护状态
            <div class="line"></div>
          </div>
        </div>
        <div class="example-box">
          <div class="item">
            <img class="fit-img" src="./assets/images/item01.png" alt="" />
            <div class="desc">
              标准化&nbsp;&nbsp;流程化&nbsp;&nbsp;全程监控&nbsp;&nbsp;透明车间
            </div>
          </div>
          <div class="item">
            <img class="fit-img" src="./assets/images/item02.png" alt="" />
            <div class="desc">
              快速投放&nbsp;&nbsp;比开店成本更低&nbsp;&nbsp;服务能力更强
            </div>
          </div>
          <div class="item">
            <img class="fit-img" src="./assets/images/item03.png" alt="" />
            <div class="desc">定价更亲民&nbsp;&nbsp;接受度更高</div>
          </div>
          <div class="item">
            <img class="fit-img" src="./assets/images/item04.png" alt="" />
            <div class="desc">更便捷&nbsp;&nbsp;解决最后一公里问题</div>
          </div>
        </div>
      </section>

      <!-- 智能柜下单 -->
      <section class="cabinet-box" id="cabinet_box">
        <div class="cabinet-bg">
          <img class="fit-img" src="./assets/images/cabinet_bg.png" alt="" />
        </div>
        <!-- 遮罩层 -->
        <div class="mask"></div>
        <div class="title-desc">
          <div class="title-text">智能柜下单</div>
          <div class="subtitle">
            <div class="line"></div>
            快速覆盖精准人群，学校、社区、酒店、健身场所
            <div class="line"></div>
          </div>
        </div>
        <div class="obj-box">
          <img class="fit-img" src="./assets/images/obj.png" alt="" />
          面向年轻化并具备消费能力的sneaker群体
        </div>
      </section>

      <!-- 洗 -->
      <section class="wash" id="wash">
        <div class="title-desc">
          <div class="title-text">标准化洗护</div>
          <div class="subtitle">
            <div class="line"></div>
            试剂、工具、材质、款式、操作步骤标准化洗护方案
            <div class="line"></div>
          </div>
        </div>
        <div class="example-box">
          <div class="item">
            <div class="left">
              <img class="fit-img" src="./assets/images/item05.png" alt="" />
            </div>
            <div class="right">
              <div class="top">
                <div class="text">
                  <div class="title">清洗</div>
                  <div class="subtitle">不怕难处理，因为我们更专业</div>
                </div>
                <div class="img">
                  <img class="fit-img" src="./assets/images/shoe.png" alt="" />
                </div>
              </div>
              <div class="bottom">
                每一双都由技师纯手工清洗，针对污渍进行专业化处理，也体现了对客户的用心程度。
                系统全面的洗护修理论知识，真正的了解材质，工具，物料，污渍以及实验原理，才
                能举一反三。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img class="fit-img" src="./assets/images/item06.png" alt="" />
            </div>
            <div class="right">
              <div class="top">
                <div class="text">
                  <div class="title">维修</div>
                  <div class="subtitle">不怕任何问题，维修更加简单</div>
                </div>
                <div class="img">
                  <img class="fit-img" src="./assets/images/fix.png" alt="" />
                </div>
              </div>
              <div class="bottom">
                不断钻研鞋包的材质和生产工艺，针对性提供最优修复专业解决方案。
                7道洗护工序，从试剂、工具到操作步骤，均根据鞋包类型，材质款式选择最优定制化
                洗护方案。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img class="fit-img" src="./assets/images/item07.png" alt="" />
            </div>
            <div class="right">
              <div class="top">
                <div class="text">
                  <div class="title">质检</div>
                  <div class="subtitle">不怕任何材质，质检伴你左右</div>
                </div>
                <div class="img">
                  <img
                    class="fit-img"
                    src="./assets/images/quality.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="bottom">
                每件物品从进厂到出厂，进出厂拍照、全程高清监控可追溯，实时了解洗护状态.每双
                物品全程投保，洗坏赔付，力争达到最高客户满意度，让客户省时、省力、省心。
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- 合作品牌 -->
      <section class="brand" id="brand">
        <div class="title-desc">
          <div class="title-text">合作品牌</div>
          <div class="subtitle">
            <div class="line"></div>
            感谢广大知名企业对我们的合作支持
            <div class="line"></div>
          </div>
        </div>
        <div class="brand-box">
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/cainiao.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/fengchao.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/jingdong.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/shunfeng.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/sudiyi.png" alt="" />
          </div>
        </div>
        <div class="brand-box">
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/baojie.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/taizi.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/jinlinbao.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/libai.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/other.png" alt="" />
          </div>
        </div>
        <div class="brand-box">
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/sudiyi.png" alt="" />
          </div>
          <div class="item">
            <img class="fit-img frame" src="./assets/images/frame.png" alt="" />
            <img class="icon" src="./assets/images/other.png" alt="" />
          </div>
        </div>
      </section>

      <!-- 联系我们 -->
      <section class="contact" id="contact">
        <div class="title-desc">
          <div class="title-text">联系我们</div>
          <div class="container">
            <div class="map">
              <img class="fit-img" src="./assets/images/map.png" alt="" />
            </div>
            <div class="right">
              <div class="item">
                <div class="title">
                  <div class="icon" style="width: 2.2292vw">
                    <img
                      class="fit-img"
                      src="./assets/images/contact.png"
                      alt=""
                    />
                  </div>
                  联系客服：
                </div>
                <div class="content">400-003-9580</div>
              </div>
              <div class="item">
                <div class="title">
                  <div class="icon" style="width: 2.2292vw">
                    <img
                      class="fit-img"
                      src="./assets/images/wechat.png"
                      alt=""
                    />
                  </div>
                  合作微信：
                </div>
                <div class="content">181-5607-5727</div>
              </div>
              <div class="item">
                <div class="title">
                  <div class="icon" style="width: 2.2292vw">
                    <img
                      class="fit-img"
                      src="./assets/images/mail.png"
                      alt=""
                    />
                  </div>
                  合作邮箱：
                </div>
                <div class="content">service@xixie.cc</div>
              </div>
              <div class="item">
                <div class="title">
                  <div class="icon" style="width: 2.2292vw">
                    <img
                      class="fit-img"
                      src="./assets/images/location.png"
                      alt=""
                    />
                  </div>
                  公司地址：
                </div>
                <div class="content">合肥市蜀山区国科军通产业园A12栋102室</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <p>
          安徽华领区块链科技有限公司<br />Anhui Hualing Block Chain Technology
          Co. LTD
        </p>
        <p>皖ICP备2021005888号-3号</p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      // 顶部tab栏相关
      tabList: [
        {
          name: '首页',
          id: '#screen'
        },
        {
          name: '洗鞋新模式',
          id: '#new_mode'
        },
        {
          name: '智能柜',
          id: '#cabinet_box'
        },
        {
          name: '标准化洗护',
          id: '#wash'
        },
        {
          name: '合作品牌',
          id: '#brand'
        },
        {
          name: '联系我们',
          id: '#contact'
        }
      ],
      tabActiveText: '#screen',

      // 移动端顶部菜单按钮相关
      showMenu: false
    }
  },
  methods: {
    // 顶部tab栏相关
    tabClick(e) {
      this.tabActiveText = e.target.dataset.id
      // 移动端切换
      const nodes = document.querySelectorAll('.top-nav-item')
      for (let i = 0; i < nodes.length; i++) {
        if (i === parseInt(e.target.dataset.index)) {
          e.target.classList.add('item-active')
        } else {
          nodes[i].querySelector('a').classList.remove('item-active')
        }
      }

      this.showMenu = false
    },

    // 移动端顶部菜单按钮相关
    menuToggle() {
      this.showMenu = !this.showMenu
    },
    handleTouchStart() {
      this.showMenu = false
    }
    // handleScroll(e) {
    //   console.log(e.target.scrollTop)
    // }
  }
}
</script>

<style lang="scss">
@import './assets/css/main.scss';

html,
body {
  height: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

// 公共类样式
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.6;
  background: #000;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fit-img {
  max-width: 100%;
  min-width: 100%;
  height: auto;
}
.title-desc {
  text-align: center;

  .title-text {
    color: #010101;
    font-weight: bold;
    font-size: 2.1875vw;
    line-height: 1;

    &::after {
      content: '';
      display: block;
      width: 2.7083vw;
      height: 0.3125vw;
      background: #0152ec;
      margin: 0.5208vw auto 0;
    }
  }

  .subtitle {
    display: flex;
    justify-content: center;
    color: #727171;
    align-items: center;
    font-size: 1.0938vw;
    margin-top: 1.0417vw;

    .line {
      height: 1px;
      background: #727171;
      width: 1.7708vw;
      margin: 0 0.5208vw;
    }
  }
}
@media screen and (max-width: 725px) {
  .title-desc {
    .title-text {
      font-size: 18px;
      &::after {
        height: 3px;
        width: 40px;
        margin: 4px auto 0;
      }
    }
    .subtitle {
      font-size: 14px;
      margin-top: 14px;
    }
  }
}
a:link,
a:visited,
a:hover,
a:active {
  color: #fff;
  text-decoration: none;
}

.wrapper {
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.top-nav,
.top-nav-box,
.seize,
.mobile-title-box {
  display: none;
}
@media screen and (max-width: 725px) {
  .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    background: #fff;
    z-index: 10;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

    h1 {
      font-size: 16px;
      margin: 0;
    }

    .menu {
      width: 40px;
      height: 30px;
      position: relative;
      color: #000;
    }
    .menu::after,
    .menu::before {
      content: '';
      display: block;
      position: absolute;
      left: 20%;
      right: 20%;
      top: 50%;
      height: 2px;
      border-radius: 100px;
      background: currentColor;
      transform: translateY(-50%);
      transition: all ease 0.3s;
    }
    .menu::after {
      box-shadow: 0 -8px currentColor, 0 8px currentColor;
    }
    .menu.close::after {
      box-shadow: none;
    }
    .menu.close::after {
      transform: translateY(-50%) rotate(45deg);
    }
    .menu.close::before {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  .top-nav-box {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    background: #fff;
    z-index: 8;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
    &.active {
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
    }

    .top-nav-item {
      &:not(:last-child) {
        border-bottom: 1px dashed #eee;
      }
      a {
        color: #000;
        display: block;
        text-align: center;
        padding: 14px;
        font-size: 18px;
        &.item-active {
          background: #f4f4f4;
        }
      }
    }
  }

  .seize {
    display: block;
    height: 50px;
  }
}

.screen {
  position: relative;

  .screen-bg {
    font-size: 0;
  }

  .top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    height: 50px;
    padding-left: 5vw;
    .logo-box {
      width: 90px;
      font-size: 0;
    }

    .tab-box {
      display: flex;
      height: 100%;
      font-size: 14px;
      color: $primary-text-color;

      .item {
        margin-right: 3.5416vw;

        &.active {
          border-bottom: 2px solid currentColor;
        }

        a {
          height: 100%;
          line-height: 48px;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }

  .container-box {
    display: flex;
    position: absolute;
    top: 11.9791vw;
    left: 23.28125vw;

    .mobile-box {
      width: 15.8333vw;
    }

    .right {
      margin-left: 6.25vw;
      padding-top: 0.7292vw;

      .title-box {
        width: 15.625vw;
        height: 3.75vw;
      }

      .sub-text {
        color: #fff;
        font-size: 2.5521vw;
        line-height: 1;
        margin-top: 1.3021vw;
      }

      .process-box {
        margin-top: 1.6667vw;
        width: 31.4583vw;
      }

      .scan-box {
        width: 16.4583vw;
        height: 9.2188vw;
        margin-top: 4.3271vw;
      }
    }
  }
}
@media screen and (max-width: 725px) {
  .screen {
    background-image: url(./assets/images/screen_bg.png);
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    padding: 130px 0 30px;
    .screen-bg {
      display: none;
    }

    .top-wrapper {
      display: none;
    }

    .container-box {
      left: 0;
      right: 0;
      justify-content: center;
      position: relative;
      top: 0;
      .mobile-box {
        width: 45vw;
      }
      .right {
        display: none;
      }
    }

    .mobile-title-box {
      display: block;
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      top: 30px;
      color: #fff;
      .mobile-title {
        font-size: 20px;
        font-weight: bold;
      }
      .mobile-subtitle {
        font-size: 16px;
        line-height: 26px;
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .item {
          margin: 0 10px;
        }
      }
    }
  }
}

.new-mode {
  background: #f6f6f6;
  padding-top: 9.375vw;
  padding-bottom: 8.75vw;

  .example-box {
    margin-top: 8.1771vw;
    display: flex;
    justify-content: center;

    .item {
      width: 16.3542vw;
      font-size: 0;
      margin: 0 5.3125vw;
      text-align: center;

      .desc {
        font-size: 0.8854vw;
        color: #221815;
        line-height: 1.25;
        margin-top: 1.4583vw;

        &::after {
          content: '';
          display: block;
          margin: 0.9896vw auto 0;
          width: 1.9792vw;
          height: 0.1563vw;
          background: #898989;
        }
      }
    }
  }

  @media screen and (max-width: 725px) {
    .example-box {
      margin-top: 0;
      flex-wrap: wrap;

      .item {
        flex-basis: 43%;
        margin: 40px 8px 0;

        .desc {
          font-size: 12px;
          line-height: 22px;
          padding: 0 10px;

          &::after {
            height: 0;
          }
        }
      }
    }
  }
}

.cabinet-box {
  position: relative;

  .cabinet-bg {
    font-size: 0;
  }

  .title-desc {
    position: absolute;
    top: 9.375vw;
    left: 0;
    right: 0;

    .title-text {
      color: #fff;
    }

    .subtitle {
      color: #fff;

      .line {
        background: #fff;
      }
    }
  }

  .obj-box {
    position: absolute;
    width: 38.9583vw;
    height: 4.4063vw;
    line-height: 4.4063vw;
    top: 16.5625vw;
    font-size: 1.7188vw;
    color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    // border: 1px dashed #eee;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
@media screen and (max-width: 725px) {
  .cabinet-box {
    .obj-box {
      position: absolute;
      width: 68.9583vw;
      height: 7.4063vw;
      line-height: 7.4063vw;
      top: 28.5625vw;
      font-size: 12px;
      color: #fff;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.wash {
  padding-top: 9.375vw;
  padding-bottom: 3.8542vw;

  .example-box {
    margin-top: 4.2188vw;

    .item {
      display: flex;
      justify-content: center;
      font-size: 0;
      margin-top: 3.8542vw;

      .left {
        flex-basis: 26.5104vw;
      }

      .right {
        flex-basis: 33.1771vw;
        margin-left: 4.2188vw;

        .top {
          display: flex;
          border-bottom: 1px solid #d6d7d7;
          padding-bottom: 1.1458vw;
          justify-content: space-between;
          align-items: flex-end;

          .text {
            color: #221815;
            line-height: 1;
            .title {
              font-size: 1.5104vw;
            }
            .subtitle {
              font-size: 1.0938vw;
              margin-top: 1.25vw;
            }
          }

          .img {
            width: 3.6458vw;
            height: 3.6458vw;
          }
        }

        .bottom {
          color: #595757;
          font-size: 0.8854vw;
          line-height: 1.5;
          margin-top: 0.9375vw;
        }
      }
    }
  }
}
@media screen and (max-width: 725px) {
  .wash {
    padding-top: 9.375vw;
    padding-bottom: 0;

    .example-box {
      margin-top: 40px;

      .item {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin-top: 30px;
        height: auto;

        .left {
          flex-basis: auto;
          margin-top: 10px;
        }

        .right {
          flex-basis: auto;
          width: 68vw;
          margin: 0 auto;

          .top {
            display: flex;
            border-bottom: 1px solid #d6d7d7;
            padding-bottom: 1.1458vw;
            justify-content: space-between;
            align-items: flex-end;

            .text {
              color: #221815;
              line-height: 1;
              .title {
                font-size: 16px;
                font-weight: bold;
              }
              .subtitle {
                font-size: 14px;
                margin-top: 15px;
              }
            }

            .img {
              width: 6.6458vw;
              height: 6.6458vw;
            }
          }

          .bottom {
            font-size: 14px;
            line-height: 1.5;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.brand {
  background: #f2f2f2;
  padding-top: 9.375vw;
  padding-bottom: 11.7188vw;

  .brand-box {
    margin-top: 7.5vw;
    display: flex;
    justify-content: center;

    &:last-child {
      margin-top: 3.6458vw;
    }
    &:nth-of-type(4) {
      display: none;
    }

    .item {
      width: 14.4792vw;
      height: 12.3438vw;
      margin-left: 1.0417vw;
      position: relative;

      .frame {
        position: absolute;
        left: 0;
        top: 0;
      }

      .icon {
        position: absolute;
        max-width: 8.4896vw;
        min-width: 8.4896vw;
        height: auto;
        top: 2.8646vw;
        left: 3.3854vw;
      }
    }
  }

  @media screen and (max-width: 725px) {
    .brand-box {
      flex-wrap: wrap;
      margin-top: 20px;

      &:last-child {
        margin-top: 0;
      }
      &:nth-of-type(4) {
        display: flex;
      }

      .item {
        width: 35vw;
        height: 28.3438vw;
        position: relative;
        margin: 20px 20px 0;

        &:nth-of-type(5) {
          display: none;
        }

        .frame {
          position: absolute;
          left: 0;
          top: 0;
        }

        .icon {
          position: absolute;
          max-width: 20.4896vw;
          min-width: 10.4896vw;
          height: auto;
          top: 6.8646vw;
          left: 8.3854vw;
        }
      }
    }
  }
}

.contact {
  padding-top: 9.375vw;
  padding-bottom: 8.9583vw;

  .container {
    margin-top: 6.7188vw;
    display: flex;
    justify-content: center;
    .map {
      flex-basis: 43.3333vw;
    }
    .right {
      margin-left: 5.4688vw;
      flex-basis: 30.7292vw;
      color: #231815;
      font-size: 1.3021vw;

      .item {
        &:not(:last-child) {
          padding-bottom: 1.2vw;
          border-bottom: 1px solid #5b5350;
        }
        &:not(:first-child) {
          margin-top: 2.1875vw;
        }

        .title {
          display: flex;
          align-items: center;
          .icon {
            margin-right: 9px;
          }
        }

        .content {
          font-weight: bold;
          text-align: left;
          margin-top: 0.9896vw;
        }
      }
    }
  }
}
@media screen and (max-width: 725px) {
  .contact {
    padding-bottom: 0;
    .container {
      margin-top: 40px;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      .map {
        flex-basis: auto;
        margin-top: 20px;
      }
      .right {
        margin: 0 auto;
        flex-basis: auto;
        width: 68vw;
        color: #231815;
        font-size: 14px;
      }
    }
  }
}

footer {
  height: 16.25vw;
  padding-bottom: 20px;
  background: #030303;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  flex-wrap: wrap;

  p {
    padding: 0 20px;
    width: 100%;
  }
}
@media screen and (max-width: 725px) {
  footer {
    height: 160px;
    font-size: 12px;
  }
}
</style>
